.alx-back-seccion{
    background-image: url('../../assets/images/bg_full.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
}
.alx-banner{
    margin-bottom: 40px;
}
.alx-img-full{
    width: 100%;
    max-width: 100%;
}
.alx-receta-min{
    margin-bottom: 40px;
    display: block;
}
.alx-receta-min img{
    margin-bottom: 10px;
    width: 100% !important;

}


.alx-color-cafe{
  color: #58392c;
}
.alx-color-naranja{
  color: #ff8101;
}
/*.alx-color-cafe{
  color: #212529; 
}*/
.alx-bold{
  font-weight: bold;
}

.justify-content-between{
    justify-content: space-between !important;
    display: flex;
}
.cQKvsR {
    width: 80px !important;
}
.alx-donde-menu{
    margin-left: -16px !important;
}
.alx-menu-mobile-align button{
    text-align: left !important;
}
@media (min-width: 1024px){
   /* .alx-menu-gral-0 .lg\:gap-\[6vw\] {
        gap: 5vw !important;
    }*/
}

@media only screen and (max-width: 991px) {
    #alx-logo-isadora-mobile{
        position: relative;
        top: -25px;
        left: 0px;
        width: 33.333%;
    }
}

@media only screen and (min-width: 992px) {
    #alx-logo-isadora{
        position: relative;
        top: -80px;
    }

}

@media only screen and (max-width: 639px) {

    .alx-donde-menu {
        margin: 0px auto 0px !important;
    }
    #alx-logo-isadora-home{
        margin: 0px auto 0px !important;
    }
}

@media only screen and (max-width: 991px) {

    .alx-back-seccion{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .alx-banner{
        margin-bottom: 10px;
    }
  
}

@media only screen and (max-width: 575px) {

    .alx-back-seccion{
        padding-top: 0px;
        padding-bottom: 20px;
    }
    
    #alx-div-banner-home-noticias{
        padding-left: 0px;
        padding-right: 0px;
    }
}