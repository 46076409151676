@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./styles/fonts.css');
@import url('./styles/news-gral.css');
@import url('./styles/alx-gral.css');
@import url('./styles/pp-gral.css');

@layer base {
  *,
  *::before,
  *::after {
    @apply antialiased;
  }

  html {
    @apply overflow-x-hidden;
  }
  ::-webkit-scrollbar {
    height: 0.25rem;
    width: 0.25rem;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: hsla(0, 0%, 32%, 0.2);
  }
}
