.pp-contenedor-producto-detalle{
    display: block;
        position: relative;
        max-width: 1500px;
        width: 90%;
        margin: 0px auto 50px;
}

.pp-producto-detalle-tabla-imagen{
    width: 300px;
}
.bg-recipes-colors-rojo{
    background-color: #CC2232;
}
@media (min-width: 993px){
    .pp-contenedor-producto-detalle{
        height:  fit-content; /* NUevo */
        min-height: 580px;
    }    
}

@media (max-width: 992px){
    .pp-producto-detalle-tabla-imagen{
        width: 220px;
    }
    .pp-menu-about-us{
        width: 100px;
    }
}
