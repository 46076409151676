.alx-hero-title{
	background-image: url('/src/assets/images/mexico-is-just-2-minutes-away.png') !important;
}
.alx-about-us-title-the-authentic-gral{
	text-align: justify-all !important;
	line-height: 50px !important;
	text-shadow: 0px 3px 6px rgba(190, 50, 0, 0.16) !important;
}
.alx-about-us-title-the-authentic{
	font-size: 67px !important;
}
.alx-about-us-title-the-authentic-gral #imported-from{
	font-size: 38px !important;
}
.alx-about-us-title-the-authentic-mexico{
	font-size: 52px !important;
}
.alx-about-us-title-the-authentic-gral #ready-to-eat{
	font-size: 39px !important;
}
.text-mf-yellow-200{
	color: #FCCF4D !important;
}
.alx-best-quality-title{
	font-size: 60px !important;
	line-height: 70px !important;
}
.alx-ingredients-title{
	font-size: 60px !important;
	line-height: 70px !important;
}

/*futrua 67.2 bold    58     0 3 6

38.4 futura bold    reyes-black 52.8

39.68 gotham-bold*/


.alx-titulo-productos-principal strong{
	font-size: 65px !important;
	line-height: 70px !important;
}
.alx-titulo-productos-principal{
	font-size: 38px !important;
	line-height: 43px !important;
}
/*
gotham black 65
gotham bold 38
*/



#alx-home-banner-contenedor{
	position: relative;
}
#alx-home-banner-contenedor-int{
	position: relative;
}
#alx-home-label-new-product{
	background: transparent url('/src/assets/images/new-product-label.png') scroll no-repeat 0px 0px; 
	background-size: 100% auto;
	max-width: 353px;
	width: 21.43%;
	height: 200px;
	display: block;
	left: 50%;
	top: -13%;
	margin-left: 16%;
	position: absolute;
	z-index: 0;
}
#alx-banner-img{
	position: relative;
	z-index: 1;
}
#alx-centro-txt-the-authentic{
	position: relative;
	z-index: 1;
}
#alx-decora-arroz{
	background: transparent url('/src/assets/images/rice-bowl.png') scroll no-repeat 0px 0px; 
	background-size: 100% auto;
	display: block;
	position: absolute;
	right: 50%;
	margin-right: 180px;
	top: 70px;
	width: 860px;
	height: 860px;
	z-index: 0;
}
#alx-decora-barbacoa{
	background: transparent url('/src/assets/images/barbacoa-bowl.png') scroll no-repeat 0px 0px; 
	background-size: 100% auto;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: 300px;
	top: 0px;
	width: 838px;
	height: 838px;
	z-index: 0;
}
#alx-muestra-producto-contenedor{
	position: relative;
	
}
#alx-muestra-producto-contenedor-2{
	text-align: center;
}
.alx-muestra-producto{
	display: inline-block;
	width: 33%;
	vertical-align: top;
}
.alx-muestra-producto h4{
	margin-top: -50px;
	margin-bottom: 40px;
}
#alx-spec-1{
	
}
.alx-color-corn{
	color: #EBB309 !important;
}
.alx-color-tomato{
	color: #CC2232 !important;
}
#alx-muestra-producto-contenedor-todo{
	width: 200%;
	position: relative;
	margin-top: -150px;
}
#alx-muestra-producto-contenedor-padre{
	display: inline-block;
	width: 50%;
	vertical-align: top;
	margin-left: 0px;
	transition: margin-left 0.5s ease;
}
#alx-muestra-producto-contenedor-2-padre{
	display: inline-block;
	width: 50%;
	vertical-align: top;
	margin-left: 0px;
	transition: margin-left 0.5s ease;
}
.alx-flecha{
	width: 40px;
	height: 40px;
	position: absolute;
	left: 25%;
	z-index: 3;
	top: 180px;
	cursor: pointer;
}
.alx-anterior{
  margin-left: -640px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 30px solid #FF7819;
}
.alx-siguiente{
  margin-left: 600px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 30px solid #FF7819;
}



/*Recipes*/

#alx-decora-recipes-1{
	background: transparent url('/src/assets/images/rice-corn-2.png') scroll no-repeat 0px 0px; 
	background-size: 100% auto;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -33%;
	top: 0%;
	width: 20%;
	padding-top: 100%; 
	z-index: 0;
}
#alx-decora-recipes-2{
	background: transparent url('/src/assets/images/rice-tomato-2.png') scroll no-repeat 0px 0px; 
	background-size: contain;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: 17%;
	top: 20%;
	width: 18%;
	padding-top: 100%; 
	z-index: 0;
}
#alx-decora-recipes-3{
	background: transparent url('/src/assets/images/recipesBanner/beef.png') scroll no-repeat 0px 0px; 
	background-size: 100% auto;
	display: block;
	position: absolute;
	left: 0%;
	top: 0px;
	width: 24%;
	padding-top: 100%; 
	z-index: 0;
}

#alx-decora-recipes-4{
	background: transparent url('/src/assets/images/recipesBanner/Puerco.png') scroll no-repeat 0px 0px; 
	background-size: contain;
	display: block;
	position: absolute;
	right: -10%;
	top: -100px;
	width: 24%;
	padding-top: 100%; 
	z-index: 0;
}
#alx-decora-recipes-5{
	background: transparent url('/src/assets/images/recipesBanner/mole.png') scroll no-repeat 0px 0px; 
	background-size: contain;
	display: block;
	position: absolute;
	right: -10%;
	top: 40%;
	width: 24%;
	padding-top: 100%; 
	z-index: 0;
}



/*DETALLES*/
.alx-seccion-productos-detalles{
	padding-top: 150px;
}
.alx-txt-center{
	text-align: center;
}
.alx-title-producto{
	border-bottom: 2px solid #59381D;
	margin: 0px auto 0px;
	width: 100%;
}
.alx-producto-etiqueta{
	margin: 0px auto;
	width: 150px;
}
.alx-bar-separador{
	width: 40%;
	height: 3px;
}
.alx-contenedor-todo-producto-detalle{
	padding-bottom: 100px;
}
.alx-contenedor-producto-detalle{
	display: block;
	position: relative;
	max-width: 1500px;
	width: 90%;
	margin: 0px auto 50px;
}
.alx-contenedor-producto-detalle-img{
	display: inline-block;
	vertical-align: top;
	width: 45%;
	position: relative;
}
.alx-contenedor-producto-detalle-img-derecha{
	float: right;
}
.alx-contenedor-producto-detalle-informacion{
	display: inline-block;
	vertical-align: top;
	width: 55%;
}
.alx-producto-detalle-imagen-principal{
	max-width: 450px;
	width: 80%;
	margin: 0px auto;
}

.alx-contenedor-producto-detalle-descripcion{
	padding-top: 30px;
	padding-bottom: 30px;
}
.alx-producto-detalle-botones{
	border-bottom: 1px solid #59381D;
}
.alx-producto-detalle-boton{
	border-radius: 10px 10px 0px 0px;
	width: 23%;
}
.alx-producto-detalle-boton.alx-boton-nutri{
	width: 31%;
}
.alx-producto-detalle-boton:hover{
	background-color: #efa14e;
}
.alx-porducto-detalle-tabla{
	position: absolute;
	top: 20px;
	left: 0px;
	width: 45%;
	z-index: 1;
}
.alx-porducto-detalle-tabla.alx-porducto-detalle-tabla-derecha{
	left: 55%;
}
.alx-porducto-detalle-tabla-int{
	position: relative;
}
.alx-producto-detalle-tabla-cerrar{
	position: absolute;
	top: 0px;
	left: 50%;
	margin-left: 150px;
	display: block;
	border-radius: 50%;
	border: 1px solid #59381D;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	font-weight: bold;
	font-size: 30px;
	background-color: #FFFFFF;
	cursor: pointer;
	opacity: 0.8;
}
.alx-producto-detalle-tabla-imagen{
	width: 300px;
}
.alx-base-clear{
	clear: both;
}

#alx-recipies-titulo-cont{
	overflow: hidden;
}
.text-recipes-colors-rojo{
	color: #CC2232;
}
.text-recipes-colors-amarillo{
	color: #EBB309;
}

.alx-cintillo-new-product{
	background: transparent url('/src/assets/images/cintillo-new-product.png') scroll no-repeat 0px 0px; 
	background-size: contain;
	display: block;
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 0px;
	width: 215px;
	height: 125px;
	margin-left: 42px;
    margin-top: 35px;
}

.alx-titulo-recipe-txt{
	font-size: 96px !important;
	text-shadow: 0px 3px 6px rgba(190, 50, 0, 0.8) !important;

}

#alx-recipies-titulo-cont{
	height: 400px !important;
}
.alx-where-to-buy-where{
	font-size: 60px !important;
	line-height: 65px !important;
	text-shadow: 0px 3px 6px rgba(190, 50, 0, 0.8) !important;
}
.alx-where-to-buy-buy{
	font-size: 60px !important;
	line-height: 65px !important;
	text-shadow: 0px 3px 6px rgba(190, 50, 0, 0.8) !important;
}






@media (max-width: 1649px) {

}

@media (max-width: 1400px) {

	.alx-cintillo-new-product{
		background-size: 38% auto;
		background-position: 93% 0px;
		left: 0%;
		top: 5%;
		width: 100%;
		height: 125px;
		margin-left: 0px;
	    margin-top: 0px;
	}

	

}

@media (max-width: 1439px) {

	/*Aboust US*/
	.alx-anterior {
	    margin-left: -560px;
	}
	.alx-siguiente{
	  margin-left: 550px;
	}

	/*Productos Detalle*/
	.alx-titulo-productos-principal strong{
		font-size: 65px !important;
		line-height: 70px !important;
	}
	.alx-titulo-productos-principal{
		font-size: 30px !important;
		line-height: 35px !important;
	}
	.alx-porducto-detalle-tabla{
		top: 10px;
	}
	.alx-producto-detalle-tabla-imagen{
	width: 270px;
	}
}
@media (max-width: 1199px) {

	/*About US*/
	.alx-about-us-parrafo {
        width: 650px;
        margin-left: auto;
        margin-right: auto;
    }
    .alx-anterior {
	    margin-left: -480px;
	}
	.alx-siguiente{
	  margin-left: 450px;
	}
  
	/*Productos Detalle*/
	.alx-porducto-detalle-tabla{
		top: 10px;
	}
	.alx-producto-detalle-tabla-imagen{
	width: 220px;
	}
	.alx-producto-detalle-boton{
		font-size: 14px;
	}
	.alx-titulo-recipe-txt{
		font-size: 80px !important;
		z-index: 1;
		position: relative;
	}
	#alx-decora-recipes-1{
		margin-left: -35%;
	}
	/*Where To But*/
	.alx-where-to-buy-contenedor{
		width: 90% !important;
		max-width: 100% !important;
	}
	.flex-col.alx-where-to-buy-contenedor{
		flex-direction: row;
	}
	.alx-where-to-buy-boton{
		width: 20% !important;
		display: inline-block !important;
		vertical-align: top !important;
	}
	.alx-where-to-buy-boton button{
		font-size: 13px !important;
	}
	.alx-menu-gral{
		font-size: 16px !important;
	}
	.alx-titulo-productos-principal strong{
		font-size: 60px !important;
		line-height: 65px !important;
	}
	.alx-titulo-productos-principal{
		font-size: 25px !important;
		line-height: 30px !important;
	}

}
@media (max-width: 991px) {
	/*About US*/
	.alx-about-us-parrafo {
        width: 600px;
    }
    #alx-muestra-producto-contenedor-todo{
    	margin-top: -50px;
    	width: 100%;
    }
    #alx-muestra-producto-contenedor-padre {
	    display: block;
	    width: 100%;
	     margin-bottom: 60px;
	}
	#alx-muestra-producto-contenedor-2-padre {
	    display: block;
	    width: 100%;

	}
    .alx-aobut-us-contenedor-best-quaily{
    	padding-bottom: 0px !important;
    }
    .alx-flecha{
    	display: none;
    }
    .alx-about-us-nombre-prod{
    	font-size: 20px !important;

    }
    .alx-muestra-producto h4{
    	margin-top: -30px;
    }



	/*Productos Detalle*/
	.alx-titulo-productos-principal strong{
		font-size: 50px !important;
		line-height: 55px !important;
	}
	.alx-titulo-productos-principal{
		font-size: 25px !important;
		line-height: 30px !important;
	}
	.alx-seccion-productos-detalles {
	    padding-top: 80px;
	}
	.alx-contenedor-producto-detalle-img{
		display: block;
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
	}
	.alx-contenedor-producto-detalle-img-derecha{
		float: none;
	}
	.alx-contenedor-producto-detalle-informacion{
		display: block;
		width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	.alx-porducto-detalle-tabla{
		position: relative;
		top: 0px;
		left: 0px;
		width: 100%
	}
	.alx-porducto-detalle-tabla.alx-porducto-detalle-tabla-derecha{
		left: 0%;
	}
	.alx-producto-detalle-boton{
		font-size: 12px;
	}
	.alx-producto-detalle-boton{
		border-radius: 10px 10px 10px 10px;
		width: 50%;
	}
	.alx-producto-detalle-boton.alx-boton-nutri{
		width: 50%;
	}
	.alx-producto-detalle-botones{
		margin-bottom: 10px;
	}
	.alx-producto-etiqueta{
		width: 120px;
	}

	/*Recipe*/
	#alx-recipies-titulo-cont{
		height: 300px !important;
	}
	.alx-titulo-recipe-txt{
		font-size: 60px !important;
	}
	.alx-miniatura-receta h3{
		font-size: 20px !important;
		line-height: 22px;
	}
	/*Where To But*/
	.alx-where-to-buy-contenedor{
		width: 90% !important;
	}
	.alx-where-to-buy-boton button{
		font-size: 12px !important;
		line-height: 14px;
	}
	

	

}
@media (max-width: 767px) {

	/*About US*/
	.alx-about-us-parrafo {
        width: 500px;
    }
    #alx-muestra-producto-contenedor-todo{
    	margin-top: -30px;
    }
    #about-us-section-bookmark{
    	margin-bottom: -20px;
    }
    .alx-about-us-title-the-authentic-gral{
		line-height: 40px !important;
		text-shadow: 0px 3px 6px rgba(190, 50, 0, 0.16) !important;
	}
	.alx-about-us-title-the-authentic{
		font-size: 55px !important;
	}
	.alx-about-us-title-the-authentic-gral #imported-from{
		font-size: 31px !important;
	}
	.alx-about-us-title-the-authentic-mexico{
		font-size: 40px !important;
	}
	.alx-about-us-title-the-authentic-gral #ready-to-eat{
		font-size: 31px !important;
	}

	.alx-best-quality-title{
		font-size: 49px !important;
		line-height: 59px !important;
	}
	.alx-ingredients-title{
		font-size: 49px !important;
		line-height: 59px !important;
	}
	.alx-where-to-buy-where{
		font-size: 49px !important;
		line-height: 59px !important;
	}
	.alx-where-to-buy-buy{
		font-size: 49px !important;
		line-height: 59px !important;
	}
   

	/*Productos Detalle*/
	.alx-titulo-productos-principal strong{
		font-size: 45px !important;
		line-height: 50px !important;
	}
	.alx-titulo-productos-principal{
		font-size: 23px !important;
		line-height: 28px !important;
	}
	.alx-contenedor-producto-detalle-informacion{
		width: 450px;
	}

	/*Recipe*/
	#alx-recipies-titulo-cont{
		height: 200px !important;
	}
	.alx-titulo-recipe-txt{
		font-size: 40px !important;
	}
	.alx-miniatura-receta{
		width: 300px !important;
		margin: 0px auto;
	}
	.alx-miniautra-recetas-contenedor{
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	/*Where To But*/
	.alx-where-to-buy-contenedor{
		width: 150px !important;
	}
	.alx-where-to-buy-boton{
		width: 100% !important;
		display: block !important;
		vertical-align: top !important;
		text-align: center;
	}
	.alx-where-to-buy-boton button{
		font-size: 16px !important;
		line-height: 18px;
	}
	.flex-col.alx-where-to-buy-contenedor {
        flex-direction: column;
    }
	


	.alx-header-redes-buy{
		display: none !important;
	}
	#mobile-menu{
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
	

	

}
@media (max-width: 575px) {
	/*Home Banner*/
	.alx-hero-banner-txt{
		min-height: 120px !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        width: 350px !important;
        margin-left: auto !important;
        margin-right: auto !important;
	}

	/*About US*/
	.alx-about-us-parrafo {
        width: 300px;
        font-size: 25px !important;
    }
    .alx-about-us-title-the-authentic-gral{
		line-height: 30px !important;
		text-shadow: 0px 3px 6px rgba(190, 50, 0, 0.16) !important;
	}
	.alx-about-us-title-the-authentic{
		font-size: 35px !important;
	}
	.alx-about-us-title-the-authentic-gral #imported-from{
		font-size: 20px !important;
	}
	.alx-about-us-title-the-authentic-mexico{
		font-size: 25px !important;
	}
	.alx-about-us-title-the-authentic-gral #ready-to-eat{
		font-size: 20px !important;
	}
	.alx-best-quality-title{
		font-size: 30px !important;
		line-height: 35px !important;
	}
	.alx-ingredients-title{
		font-size: 30px !important;
		line-height: 35px !important;
	}
	
	.alx-muestra-producto {
	    display: block;
	    width: 280px;
	    margin-left: auto;
	    margin-right: auto;
	}
	.alx-spec-mobile-ocultar{
		border: 1px solid #59381D;
		margin: 0px auto;
		width: 280px !important;
	}
	.alx-spec-mobile-ocultar img{
		display: none;
	}
	.alx-spec-mobile-ocultar-2{
		margin: 0px auto;
		width: 280px !important;
	}
	.alx-spec-mobile-ocultar-2 img{
		display: none;
	}
	#alx-spec-1-mobile-1{
		background: transparent url('/src/assets/images/ingredients-icons.png') scroll no-repeat -13px 0px; 
		background-size: 800px auto;
		height: 120px;
		width: 300px;
		margin: 0px auto;
	}
	#alx-spec-1-mobile-2{
		background: transparent url('/src/assets/images/ingredients-icons.png') scroll no-repeat -315px 0px; 
		background-size: 800px auto;
		height: 120px;
		width: 300px;
		margin: 0px auto;
	}
	#alx-spec-1-mobile-3{
		background: transparent url('/src/assets/images/ingredients-icons.png') scroll no-repeat -615px 0px; 
		background-size: 800px auto;
		height: 120px;
		width: 180px;
		margin: 0px auto;
	}
	#alx-spec-2-mobile-1{
		background: transparent url('/src/assets/images/rice-spec.png') scroll no-repeat -116px 0px; 
		background-size: 800px auto;
		height: 120px;
		width: 293px;
		margin: 0px auto;
	}
	#alx-spec-2-mobile-2{
		background: transparent url('/src/assets/images/rice-spec.png') scroll no-repeat -415px 0px; 
		background-size: 800px auto;
		height: 120px;
		width: 293px;
		margin: 0px auto;
	}
	#about-us-section-bookmark {
        margin-bottom: 0px;
    }


    /*Productos Detalle*/
    .alx-titulo-productos-principal strong{
		font-size: 35px !important;
		line-height: 40px !important;
	}
	.alx-titulo-productos-principal{
		font-size: 20px !important;
		line-height: 25px !important;
	}
	.alx-contenedor-producto-detalle-informacion{
		width: 300px;
	}

	/*Recipe*/
	.alx-titulo-recipe-txt{
		font-size: 35px !important;
	}
	
	#alx-recipies-titulo-cont{
		min-height: auto;
    	height: 150px !important;
	}
	.alx-miniatura-receta{
		width: 280px !important;
		margin: 0px auto;
	}
	.alx-where-to-buy-where{
		font-size: 35px !important;
		line-height: 40px !important;
	}
	.alx-where-to-buy-buy{
		font-size: 35px !important;
		line-height: 40px !important;
	}
	.alx-contact-titulo{
		font-size: 30px !important;
		line-height: 33px !important;
	}

}
@media (max-width: 390px) {
    /*Home Banner*/
	.alx-hero-banner-txt{
		min-height: 100px !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        width: 300px !important;
        margin-left: auto !important;
        margin-right: auto !important;
	}

	/*Productos Detalle*/
	.alx-contenedor-producto-detalle-img{
		width: 280px;

	}
	.alx-contenedor-producto-detalle-informacion{
		width: 280px;
	}

	/*Recetas Filtro*/
	.flex.alx-padre-botones-recetas-filtrado{
		display: block;
		width: 300px;
	}
	.alx-button-filtrar-recetas{
		display: inline-block;
		width: 31% !important;
		border-radius: 10px !important;
		margin-bottom: 5px;
		margin-left: 1%;
		margin-right: 1%;
	}
	.alx-button-filtrar-recetas.boton-filtra-tomato, .alx-button-filtrar-recetas.boton-filtra-corn{
		width: 48% !important;
		margin-left: 1%;
		margin-right: 1%;
	}
	.alx-where-to-buy-parrafo{
		width: 290px;
	}
	.alx-contenedor-producto-detalle-informacion{
		width: 250px;
	}

	.alx-cintillo-new-product{
		background-size: 36% auto;
		background-position: 92% 0px;
		top: 5%;
		
	}
}
@media (max-width: 350px) {

	/*Productos Detalle*/
	.alx-contenedor-producto-detalle-img{
		width: 250px;

	}
	
}

@media (max-width: 1023px) {

	/*Seccion Recetas, filtrado en mobile*/
	.barbacoa-ver div.grupo-mole, .barbacoa-ver div.grupo-pork, .barbacoa-ver div.grupo-tomato, .barbacoa-ver div.grupo-corn {
		display: none !important;
	}
	.mole-ver div.grupo-barbacoa, .mole-ver div.grupo-pork, .mole-ver div.grupo-tomato, .mole-ver div.grupo-corn {
		display: none !important;
	}
	.pork-ver div.grupo-mole, .pork-ver div.grupo-barbacoa, .pork-ver div.grupo-tomato, .pork-ver div.grupo-corn {
		display: none !important;
	}
	.tomato-ver div.grupo-mole, .tomato-ver div.grupo-pork, .tomato-ver div.grupo-corn, .tomato-ver div.grupo-barbacoa {
		display: none !important;
	}
	.corn-ver div.grupo-mole, .corn-ver div.grupo-pork, .corn-ver div.grupo-tomato, .corn-ver div.grupo-barbacoa {
		display: none !important;
	}

	.bg-recipes-colors-rojo{
		background-color: #CC2232;
	}
	.bg-recipes-colors-amarillo{
		background-color: #EBB309;
	}
	.text-recipes-colors-rojo{
		color: #CC2232;
	}
	.text-recipes-colors-amarillo{
		color: #EBB309;
	}
	.bg-recipes-colors-rojo.bg-opacity-50{
		opacity: 0.5;
	}
	.bg-recipes-colors-amarillo.bg-opacity-50{
		opacity: 0.5;
	}
	.alx-button-filtrar-recetas{
		font-size: 12px !important;
	}
}

