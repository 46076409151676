@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,600&display=swap");

@font-face {
    font-family: "Futura";
    src: url("../assets/fonts/Futura.ttf") format("truetype");
}

@font-face {
    font-family: 'FuturaBold';
    src: url('../assets/fonts/FuturaBold/Futura-Bold.eot');
    src: url('../assets/fonts/FuturaBold/Futura-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/FuturaBold/Futura-Bold.woff2') format('woff2'),
        url('../assets/fonts/FuturaBold/Futura-Bold.woff') format('woff'),
        url('../assets/fonts/FuturaBold/Futura-Bold.ttf') format('truetype'),
        url('../assets/fonts/FuturaBold/Futura-Bold.svg#Futura-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Gotham Bold";
    src: url("../assets/fonts/Gotham/Gotham-Bold.eot");
    src: url("../assets/fonts/Gotham/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Gotham/Gotham-Bold.woff2") format("woff2"),
        url("../assets/fonts/Gotham/Gotham-Bold.woff") format("woff"),
        url("../assets/fonts/Gotham/Gotham-Bold.ttf") format("truetype"),
        url("../assets/fonts/Gotham/Gotham-Bold.svg#Gotham-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Ultra";
    src: url("../assets/fonts/Gotham/Gotham-Ultra.otf");
    src: url("../assets/fonts/Gotham/Gotham-Ultra.woff2") format("woff2"),
        url("../assets/fonts/Gotham/Gotham-Ultra.woff") format("woff"),
        url("../assets/fonts/Gotham/Gotham-Ultra.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Medium";
    src: url("../assets/fonts/Gotham-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham Book";
    src: url("../assets/fonts/Gotham/Gotham-Book.eot");
    src: url("../assets/fonts/Gotham/Gotham-Book.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Gotham/Gotham-Book.woff2") format("woff2"),
        url("../assets/fonts/Gotham/Gotham-Book.woff") format("woff"),
        url("../assets/fonts/Gotham/Gotham-Book.ttf") format("truetype"),
        url("../assets/fonts/Gotham/Gotham-Book.svg#Gotham-Book") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BERNIER Distressed";
    src: url("../assets/fonts/Bernierd/BERNIERDistressed-Regular.eot");
    src: url("../assets/fonts/Bernierd/BERNIERDistressed-Regular.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Bernierd/BERNIERDistressed-Regular.woff2") format("woff2"),
        url("../assets/fonts/Bernierd/BERNIERDistressed-Regular.woff") format("woff"),
        url("../assets/fonts/Bernierd/BERNIERDistressed-Regular.ttf") format("truetype"),
        url("../assets/fonts/Bernierd/BERNIERDistressed-Regular.svg#BERNIERDistressed-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Alternate Gothic No3 D";
    src: url("../assets/fonts/Alternate/AlternateGotNo3D.eot");
    src: url("../assets/fonts/Alternate/AlternateGotNo3D.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Alternate/AlternateGotNo3D.woff2") format("woff2"),
        url("../assets/fonts/Alternate/AlternateGotNo3D.woff") format("woff"),
        url("../assets/fonts/Alternate/AlternateGotNo3D.ttf") format("truetype"),
        url("../assets/fonts/Alternate/AlternateGotNo3D.svg#AlternateGotNo3D") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Kellog";
    src: url("../assets/fonts/Kellog/Filmotype-Kellog.ttf") format("truetype"),
        url("../assets/fonts/Kellog/Filmotype-Kellog.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Alternate Gothic No2 D";
    src: url("../assets/fonts/Alternate/AlternateGothicNo2DRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Reyes Black";
    src: url("../assets/fonts/ReyesBlack/YockMercado-ReyesBlack.otf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("../assets/fonts/Oswald/Oswald-DemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FuturaBold';
    src: url('../assets/fonts/FuturaBold/Futura-Bold.eot');
    src: url('../assets/fonts/FuturaBold/Futura-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/FuturaBold/Futura-Bold.woff2') format('woff2'),
        url('../assets/fonts/FuturaBold/Futura-Bold.woff') format('woff'),
        url('../assets/fonts/FuturaBold/Futura-Bold.ttf') format('truetype'),
        url('../assets/fonts/FuturaBold/Futura-Bold.svg#Futura-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*
 * Tamaños de fuente especificos
 */

small.small-trademark {
    font-size: .9rem;
    vertical-align: super;
}

h2>span#imported-from {
    font-size: 2.4rem;
}

h2>span#imp-mexico {
    font-size: 3.3rem;
}

h2>p#ready-to-eat {
    font-size: 2.48rem;
}

h3.lineHeight-09 {
    line-height: 0.9;
}

div>p.font-s-1-8 {
    font-size: 1.8rem;
}

h2>span.custom-fs-4-2r{
    font-size: 4.2rem;
}


/*
 * Otros
 */
@media screen and (min-width: 1024px) {
    #recipe-detail-ing-prep {
        margin-bottom: -50px;
    }
}

@media screen and (min-width: 768px) {
    .md-custom-pr--10 {
        padding-right: 2rem;
    }
}

@media screen and (max-width: 576px) {
    .margin-x-2-mobile{
        margin: 0 2rem;
    }
    div.mobile-0-padding-x{
        padding: 0 0;
    }
    div.mobile-padding-x-0{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    div>h2>span.the-auth-m{
        font-size: 3.8rem;
    }
}

.px-4rem {
    padding: 0 4rem;
}

#beef-img-banner-recipes{
    max-width: 200%;
    width: 110%;
}